// import styled from 'styled-components';
import {StyledTabContainer} from "../SocialFeeds/styles";
import styled, {css} from "styled-components";
import {CustomDropDown} from "../PeopleHomeV2/styles"

const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';
const RegularFont = 'Rubik-Light';



const EmployeeTab = styled.div`
  height: auto;
  margin-top: 15px;
  @media (max-width: 500px) {
    padding: 20px 10px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 26px);
  height: 100%;
  float: left;
  font-size: 12px;
  // text-transform: uppercase;
  text-align: left;
  margin-bottom: 25px;
  justify-content: flex-start;
  // column-gap: 24px;
  h3 {
    font-family: ${RegularFont};
    font-size: 20px;
    color: #000;
    padding-left: 5px;
    text-transform: capitalize;
  }
`;


const CardContainerr = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 26px);
  height: 100%;
  float: left;
  font-size: 12px;
  // text-transform: uppercase;
  text-align: left;
  margin-bottom: 25px;
  justify-content: flex-start;
  // column-gap: 24px;
  h3 {
    font-family: ${RegularFont};
    font-size: 20px;
    color: #000;
    padding-left: 5px;
    text-transform: capitalize;
  }
`;


const ChampionsCard = styled.div`
  display: inline-block;
  width: calc(100%/4);
  float: left;
  @media (max-width: 1200px) {
    width: calc(100%/3);
  }
  @media(max-width: 760px) {
    width: calc(100%/2);
    float: left;
  } 
  @media(max-width: 500px) {
    width: 100%;
  }
  > div {
    height: 100%;
    padding: 10px 5px;
  }
`;

const HeaderTitle = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  font-family: ${BoldFont};
  text-align: left;

  > span {
    color: #59bbd9;
  }
  
  > p {
   margin: 0px 0 0 10px;
  //  text-transform: uppercase;
   font-family: ${BoldFont};
   font-size: 18px;
   color: #0D4270;
  } 

  > img {
    width: 19px;
    height: 19px;
    margin-left: 5px;
  }
`;

const RecognitionCardContainer = styled.div`
  background-color: #fff;
  border-radius: 6px;
  text-align: center;
  min-height: 341px;
  width: 100%;
  font-size: 12px;
  position: relative;
  height: 100%;
  
  .champion-tag {
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    > p {
    font-family: ${BoldFont};
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    background: #1E76AB;
    margin: 0;
    border-radius: 6px 6px 0px 0px;
    letter-spacing: 1px;
    }
  }
  .state-city {
    background-color: rgba(151,151,151,0.3);
    font-family: ${MediumFont};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #282C37;
    display: block;
    border-radius: 0 0 6px 6px;
    width: 100%;
    float: left;
    min-height: 44px;
  }  
  > div{
    width: 100%;
    height: 100%;
    > div {
       padding: 10px;
      .image {
        height: auto;
        width: auto;
        border-radius: 50%;
        padding: 20px 0 0 0;
        > img {
          height: 85px;
          width: 85px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .company {
        font-weight: normal;
        text-transform: capitalize;
        color: rgb(153,153,153);
        font-size: 12px;
      }
    }
    .button {
      margin-top: 20px;
      display: none;
      > span{
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  }
  .name {
    font-family: ${MediumFont};
    font-size: 17px;
    text-transform: capitalize;
    color: #282C37;
    padding: 0;
  }
  
  .designation {
    min-height: 60px;
    padding-top: 6px;
    color: rgba(40,44,55,0.6);
    font-size: 14px;
    text-transform: none;
    font-family: ${BoldFont}
    
  }
  
  .color-band {
    font-family: ${BoldFont};
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: #A5ABBA;
    text-transform: none;
    width: 100%;
    float: left;
    min-height: 44px;
  }
`;

const UserInterest = styled.div`
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap;
    padding: 0 3px !important;
    margin: 0 auto;
    min-height: 44px;
`;

const ImageContainer = styled.div`
    border-radius: 50%;
    width: 37px;
    display: flex;
    height: 37px;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    margin: 0px 3px;
     > img {
      width: 20px !important;
      height: 20px !important;
     }
`;

const TitleContainer = styled.div`
    float: left;
    width: 100%;
    padding: ${({padding}) => padding ? padding : '5px 0 5px 5px'};
    background-color: ${({bgColor}) => bgColor ? bgColor : 'none'};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : 'none'};
    & > img {
      float: left;
    }
    & > div {
      float: right;
      display: flex;
      align-items: center; 
      > a {
        display: block;
        font-size: 14px;
        color: rgb(51,51,51); 
        font-family: ${BoldFont};
       
      } 
      > img {
          width: 8px;
          margin-left: 10px;
      } 
      > i {
          font-size: 25px;
          color: #fff;
          line-height: 0;
      }  
    }
`;

const Title = styled.p`
   font-size: ${({fontSize}) => fontSize ? fontSize : '14px'};
   letter-spacing: 0.8px;
   color: rgb(51,51,51);
   font-family: ${MediumFont};
   font-weight: ${({fontWeight}) => fontWeight ? fontWeight : '700'};
   text-transform: ${({textTransform}) => textTransform ? 'uppercase' : 'none'};
   line-height: 18px;
   text-align: left;
   float: left;
   margin: 0;
   color: ${({textColor}) => textColor ? textColor : 'none'};
   max-width: ${({maxWidth}) => maxWidth ? maxWidth : 'none'};
   padding: ${({padding}) => padding ? padding : '0'};
   
   @media (max-width: 1200px) and (min-width: 1024px) {
    max-width: none;
   }
   
   @media (max-width: 1023px) and (min-width: 700px) {
    max-width: 600px;
   }
    
`;

const StyledButton = styled.button `
   color: ${({btnTextColor}) => btnTextColor ? btnTextColor : '#1C4667'};
   float: right;
   background: none;
   border: none;
   font-size: 14px;
   outline: none;
   & > img {
     width: 8px;
     margin-left: 10px;
        
   }
`;
const StyledTabs = styled(StyledTabContainer)`
  
  .tab-content {
    position: relative;
    
    @media (max-width: 500px) {
      margin-top: -48px;
    }
  }
  
  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;
    
    @media (max-width: 650px) {
      float: left;
      margin-left: 63px;
    }
    
    @media (max-width: 500px) {
        display: inline-block;
        float: none;
        margin: 0 0 0 33px;
        padding-top: 10px;
    }
  }
  
  > ul {
    @media (max-width: 650px) {
      min-height: 135px
    }
    
    @media (max-width: 500px) {
      min-height: 200px;
    }
    > li {
      @media (max-width: 500px) {
        float: none;
        width: 135px;
        margin: 0 auto;
      }
      > a {
        font-size: 15px;
        //color: rgb(51,51,51);
        color: #CCCBDO;
        text-transform: none;
        font-family: ${MediumFont};
        
        @media (max-width: 500px) {
        padding-left: 0;
        padding-right: 0
        margin: 0
       }
      }
    }

    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }

  li.first-tab.active {
    > a {
      color: #0169b2
      border-bottom: 3px solid #0169b2;
      font-family: ${BoldFont}
    }
  }
  li.second-tab.active {
    > a {
      color: #0169b2
      border-bottom: 3px solid #0169b2;
      font-family: ${BoldFont}
    }
  }
  li.third-tab.active {
    > a {
      color: #0169b2
      border-bottom: 3px solid #0169b2;
      font-family: ${BoldFont}
    }
  }
`;



const RecognitionHeader = styled.div`
  width: 100%;
  background: ${({background}) => background ? background : 'white'};
  border-radius: 4px;
  color: #fff;
  padding: 1px 15px;
  font-family: ${BoldFont};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  .recognise-title{
    height: 24px;
    left: 452px;
    top: 460px;    
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;   
    color: #0D4270;
    margin-top: 15px;
    
  }
  >div{
      border: none;
      background: none;
    .download_img{
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin-top: 9px;
    }
  }
`;

const TopRecognitionContainerV2 = styled.div`
    flex-wrap: wrap;
    display: flex;
    width: 32%;
    max-width: 399px;
    margin: 0px 0;
    justify-content: ${({justify}) => justify ? '' : 'space-between'};
    margin-right: 25px;
`;


const RecognitionReceivedContainerV2 =  styled.div`
 float: left;
 width: 100%;
//  margin-right: 25px;
 margin-bottom: 23px;
 background-color: #fff;
//  margin-right:  ${({marginRight}) => marginRight ? marginRight : '0px'};
 display: flex;
 flex-direction: column;
 align-items: center;
//  &:last-child {
//   margin-right: 0;
//  }
 
 @media (max-width: 1200px) {
  width: calc((100% - 20px) / 2);
 }

 @media (max-width: 1260px)  and (min-width: 1200px){
	width: calc((100% - 30px) / 3);
 }
 
 @media (max-width: 575px) {
  width: 100%;
  margin- right: 0;
 }
`;

const RecognitionReceivedHeaderV2 = styled.div`
  ${'' /* width: 100%;
  background: ${({background}) => background ? background : 'white'}; */}
  ${'' /* border-radius: 4px; */}
  color: #fff;
  padding: 1px 15px;
  font-family: ${BoldFont};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  ${'' /* display: flex; */}
  justify-content: space-between;
  width: 100%;
  display: flex;
  // justify-content: flex-start;
  align-items: center;
  // width: 405px;
  height: 50px;
  background: #005c87;
  border-radius: 6px 6px 0px 0px;
  .recognise-title{
    height: 24px;
    left: 452px;
    top: 460px;    
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;   
    color: #0D4270;
    margin-top: 15px;
    
  }
  >div{
      border: none;
      background: none;
    .download_img{
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin-top: 9px;
    }
  }
  
  > img {
		float: left;
		width: 22px;
    height: 22px;
  }
  
  > span {
    float: left;
		margin-left: 10px;
		text-transform: capitalize;
  }
`;

const RecognisedUserV2 = styled.div`
   width: 93%;
   height: auto;
   background-color: #fff;
   / float: left; /
   display: flex;
   align-items: center;
   border-bottom: 1px solid #E0E0E7;
   &:last-child {
    border-bottom: none;
    .viewAllMembers{
    width: calc(100% + 30px);
    margin-left: -15px;
    height: 25px;
    background: rgba(234,234,234,1);
    cursor: pointer;
    border-radius: 0 0 6px 6px;
    font-family: Rubik;
    font-size: 12px;
    line-height: 16px;
    color: #6096b0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  
  }
   }
`;

const UserProfileContainerV2 = styled.div`
   width: 100%;
   float: left;
   height: 70px;
   border-bottom: 1px solid #E0E0E7;
   &:last-child {
    border-bottom: none;
   }

   div{
    > img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
    float: left;
   }

   }
    > img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
    float: left;
   }
   .wellnessChampion {
      margin: 0;
   }
    @media (max-width: 1200px) {
     float: left;
     margin: 0
     min-width: 110px;
    }
   }
   
   .active {
     color: rgb(21,159,202);
   }
`;

const UserNameContainerV2 = styled.div`
width:'100%';
display: flex;
flex-direction: column;
> span{
  width: 103px;
  height: 14px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 2px;
}
> p {
    font-family: "Rubik";
    font-size: 10px;
    color: #1E76AB;
    / margin-left: 40px; /
    width: calc(100% - 52px);
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    background-size: 100% 100%;
    color: rgb(255, 255, 255);
    background-image: url(/images/wellness.png);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    line-height: 9px;
    width: 132.12px;
    height: 18px;
    margin-bottom: 0px;
   }
   
   & > p:nth-child(3) {
    font-family: Rubik-Regular;
    font-size: 10px;
    // color: #1E76AB;
    margin: 0;
    width: calc(100% - 144px);
    display: block;
    padding-top: 1px;

`;

const NameV2 = styled.div`
   float: left;
   font-family: ${({fontFamily}) => fontFamily ? fontFamily : 'Rubik-Medium'};
   font-size: 14px;
   color: ${({color}) => color ? color : '#0D4270'};
   max-width: 140px;
   white-space: nowrap; 
   overflow: hidden;
   text-overflow: ellipsis;
   margin: ${({margin}) => margin ? '7px 0px 0px 0px' : '16px 0 0 0'};
   margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
   ${({active}) => active && css`
    // color: rgb(21,159,202);
  `}
   @media (max-width: 500px) {
    text-align:  ${({textAlign}) => textAlign ? 'left' : 'center'};
    width: auto;
    float: none;
    max-width: none;
   }
`;

const RecognitionFrequencyV2 = styled.div`
   width: 100%;
   float: left;
   text-align: right;
  //  margin-top: 25px;
   > span {
    font-family: ${MediumFont};
    font-size: 14px;
    color: ${({color}) => color ? color : '#005c87'};
   }
`;

const NewLabelV2 = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 21px;
text-align: center;
color: #9C9C9C;
margin-right: 10px;
position: relative;
//bottom: 44px;
// right: 275px;
bottom: ${({role}) => (role === 'ADMIN') ? '3px' : '0px'};
margin-bottom: 0px;
`;

const YearDropDownV2 = styled(CustomDropDown)`
  float: right;
  align-items: center;
  display:flex;
  ${'' /* margin-left: 60px; */}
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 100%;
  .dropdown.btn-group{
    width:100% !important;
    height:200px !important;
  }

  > span {
    float: right;
    top: 20px;
    position: absolute;
    right: 10px;
    height:100px !important;
    overflow:scroll;
  }

  height: 40px;
  //background: #69C2FF;
  background: #FFFFFF;
  border: 1px solid rgba(156, 156, 156, 0.4);
  border-radius: 6px;
  box-shadow: none;
  margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : '0px'};
  padding-top: 8px;
  .caret{
    color:#69C2FF;
    margin-right: 0px;
    margin-top: -14px;
    border-width: 8px;
    margin-left:10px;
    display:none;
  }
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  }

  button{
    background:transparent !important;
    border:none;
    ${'' /* color:#005C87; */}
  }

  button:hover{
    color:#005C87;
  }

  button:focus{
    background-color: transparent !important;
  }
  button:focus-visible{
    background-color: transparent !important;
  }
  button:active{
    background-color: transparent !important;
  }

  .dropdown-toggle::after {
    display:none;
  }
`;

const CardContainerV3 = styled.div`
 width: calc((100% - 100px) / 4);
 border-radius: 6px;
 background: ${({deleted}) => deleted ? '#FFC0CB' :'#fff'};
 margin-bottom: 25px;
 margin-right: ${({marginRight}) => marginRight ? marginRight : '0'};
 border: ${({deleted,active}) => deleted?"1px solid #FD7175":active ? '1px solid rgba(0, 47, 71, 1)' : '1px solid rgba(0, 47, 71, 1)'};
 &:hover, &:focus, &:active {
  filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);
}
`;

const GridCardV2 = styled.div`
width: 100%;
min-height: 330px;
cursor: pointer;
border-radius: 6px;
.wellness-champion{
  width: 100%;
  height: 20px;
  margin-bottom: 5px;

  >div{
  width: 100%;
  background: linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%);
  background: ${({deleted}) => deleted ? '#FD7175' :'linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)'};
  margin-bottom: 5px;
  display: flex;
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 6px 6px 0 0;
  }
}
.menu-button{
  width:100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >img{
    height: 6px;
    width: 24px;;
    cursor: pointer;
    margin-right: 10px;
    margin-top: -8px;
  }
}
.remove-button{
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: flex-end;
  >div{
    width:118px;
    height:40px;
    background: white;
    border-radius: 6px;
    filter: drop-shadow(rgba(58, 58, 58, 0.2) 5px 4px 10px);

  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#0D4270;
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 10px 10px 0 0;
  }
}
.profile{
  width: 100%;
  margin-bottom 10px;
  >div{
    width: 90px;
    height: 90px;
    margin: auto;
    >img{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
}
.name{
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  color:#0D4270;
  width: 100%;
  display: flex;
  margin-bottom 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#9c9c9c;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.no-department{
  font-family: Rubik;
  font-size: 14px;
  line-height: 20px;
  color:#0D4270;
  width: 100%;
  display: flex;
  margin-bottom 10px;
  justify-content: center;
  align-items: center;
}
.wellness-card{
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  >div{
    width: 32px;
    height: 32px;
    display: flex;
    margin-right: 5px;
    background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
    border-radius: 6px;
    >img{
      width: 20px;
      height: 20px;
      margin: auto;
    }
    .hover-card{
      width: 0px;
      height: 0px;
      position: relative;
      display: block;
      >div{
        min-width: 90px;
        max-width: 120px;
        min-height: 25px;
        position: relative;
        right: 50px;
        bottom: 27px;
        background: rgba(156, 156, 156, 0.79);

        font-family: rubik;
        font-size: 12px;
        line-height: 12px;
        color: white;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        border-radius: 6px;
        padding: 5px 7px;
      }
    }
  }
}
.mutual-buddies{
  width: 100%;
  background: #F3F6F9;
  >div{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50px;
    .text{
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      margin-right: 10px;
      color: #0D4270;
    }
    .no-text{
      font-family: Rubik;
      font-size: 16px;
      line-height: 20px;
      align-items: center;
      margin: auto 0px;
      // margin-right: 10px;
      color: #0D4270;
    }
    .profile-image-card{
      display: flex;
      margin-left: 12px;
      >img{
        width: 34px;
        height: 34px;
        margin: auto 0px;
        border-radius: 50%;
        margin-left: -12px;
        border: 2px solid white;
      }
      .count{
        display: 100%;
        background: linear-gradient(360deg,#1E6481 0%,#0D425C 60.94%,#022C43 100%);
        font-family: Rubik;
        font-size: 12px;
        color: #fff;
        padding: 0px 8px;
        height: 34px;
        margin: auto;
        border-radius: 15px;
        margin-left: -12px;
        align-items: center;
        text-align: center;
        display: flex;
        border: 2px solid white;
      }
    }
  }
}
.button-cotainer{
  width: 100%;
  margin: 0px auto;
  padding: 10px 0px;
}
`;

const RequestButtonV2 = styled.div`
width: 160px;
height: 40px;
display: flex;
font-family: Rubik-Medium;
font-size: 18px;
line-height: 24px;
background: #FD7175;
background: ${({ background }) => background ? background : "#FD7175"};
margin: ${({ margin }) => margin ? margin : "auto"};
color: #fff;
justify-content: center;
align-items: center;
cursor: pointer;
border-radius: 6px;
 `;
 

export { EmployeeTab, CardContainer , ChampionsCard, HeaderTitle, StyledButton, Title, TitleContainer, ImageContainer, UserInterest, RecognitionCardContainer,StyledTabs, TopRecognitionContainerV2,RecognitionReceivedContainerV2 ,RecognitionReceivedHeaderV2,RecognisedUserV2,UserProfileContainerV2,UserNameContainerV2,NameV2,RecognitionHeader, RecognitionFrequencyV2, NewLabelV2, YearDropDownV2, CardContainerV3, GridCardV2, RequestButtonV2, CardContainerr}

